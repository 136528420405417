import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../../components/Layout';
import Banner from '../../components/Banner';
import DonorfyForm from '../../components/DonorfyForm';

const PayPage = ({ location: { state } }) => {
  const {
    datoCmsPay: { seoMetaTags, title, bannerImage, bannerText },
  } = useStaticQuery(graphql`
    query PayPageQuery {
      datoCmsPay {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerImage {
          gatsbyImageData
          alt
        }
        bannerText
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner heading={title} image={bannerImage} text={bannerText} />
        <DonorfyForm
          amount={state && state.amount && parseInt(state.amount).toFixed(2)}
          regular={state && state.regular}
        />
      </main>
    </Layout>
  );
};

export default PayPage;
